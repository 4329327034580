const topicOptions = {
  company: [
    'Klienhelm',
    'SoftTube',
    'Brainworx',
    'SPL',
    'Antares',
    'Cableguys',
    'Celemony‍',
    'East West‍',
    'Eventide',
    'FabFilter‍',
    'Illformed‍',
    'iZotope‍',
    'LennarDigital‍',
    'MeterPlugs‍',
    'Native Instruments‍',
    'oeksound‍',
    'Plugin Alliance‍',
    'Slate Digital‍',
    'Sonarworks',
    'Sonnox‍',
    'Soundtoys‍',
    'Spectrasonics‍',
    'Synchro Arts‍',
    'u-he‍',
    'Universal Audio‍',
    'Waves',
    'Waves Factory',
    'Xfer Records',
    'XLN Audio',
    ' Analog Obsession',
    'Antares',
    'Acoustica Audio',
    'Arturia',
    'Cymatics',
    'elysia',
    'Electronik Sound Lab',
    'UnitedPlugins',
    'Matthew Lane',
    'Fuse Audio',
    'SoundSpot',
    'Caelum Audio',
    'emvoice',
    'Ampeg',
    'Black Box Analog Design',
    'Bettermaker',
  ],
  functions: [
    'SPACER-Category ~ Category',
    'Category ~ Audio Effects',
    'Category ~ Synthesizer',
    'Category ~ Sampler',
    'Category ~ Percussion Synth or Sampler',
    'Category ~ MIDI Arpeggiator or Effects',
    'Category ~ Music Generator',
    'Category ~ DAW',
    'Category ~ Loop library',
    'SPACER-FrequencyControl ~ Frequency Control',
    'Frequency Control ~ EQ',
    'Frequency Control ~ Filter',

    'SPACER-Dynamics ~ Dynamics',
    'Dynamics ~ Compressor',
    'Dynamics ~ Multi-band Comp',
    'Dynamics ~ Limiter',
    'Dynamics ~ Clipper',
    'Dynamics ~ Gate or Expander',
    'Dynamics ~ De-Esser',

    'SPACER-Saturation ~ Saturation',
    'Saturation ~ Distortion',
    'Saturation ~ Harmonics',
    'Saturation ~ Subharmonics',
    'Saturation ~ Exciter',

    'SPACER-TimeandSpace ~ Time and Space',
    'Time and Space ~ Reverb',
    'Time and Space ~ Delay',
    'Time and Space ~ Stereo Mono and Mid-Side Control',

    'SPACER-Modulation ~ Modulation',
    'Modulation ~ Chorus-Flanger-Phaser-Tremolo',
    'Modulation ~ Pitch-Shifter',
    'Modulation ~ Glitch or Granular',
    'Modulation ~ General Modulation',

    'SPACER-Simulation ~ Simulation',
    'Simulation ~ Room Sim',
    'Simulation ~ Cabinet Sim',
    'Simulation ~ Mic Sim',
    'Simulation ~ Amp Sim',
    'Simulation ~ Preamp Sim',

    'SPACER-CombinationTools ~ Combination Tools',
    'Combination Tools ~ Channel Strip',
    'Combination Tools ~ Effects Rack',
    'Combination Tools ~ Vocal-Specific Processing',

    'SPACER-Multi-FunctionShaper ~ Multi-Function Shaper',
    'Multi-Function Shaper ~ Spectral',
    'Multi-Function Shaper ~ Transient',
    'Multi-Function Shaper ~ Enhancer',

    'SPACER-Analyzers ~ Analyzers',
    'Analyzers ~ Meter',
    'Analyzers ~ Tuner',
    'Analyzers ~ Spectral Analysis',
    'Analyzers ~ Plugin Analysis',

    'SPACER-User Added ~ User Added',
  ],
  precision: ['Vibey Analog', 'Analog Mastering', 'Digital Precision'],
  color: [
    "Vintage ('60's & Earlier)",
    "70's",
    "80's",
    'Modern',
    'Extreme',
    'Dark Atmosphere',
    'Light Atmosphere',
    'Tribal & Earthy',
    'Asian',
    'European',
    'Latin',
    'Sci-Fi',
  ],
  status: ['Active', 'Demo', 'Disabled', 'Wanted'],
};

export default topicOptions;
